function reformatNews(target = ".msl-news", defaultimage = "https://code.derbyunion.co.uk/usdev/images/us-scribble.svg") {
  const mslnewsHomePage = Array.from(document.querySelectorAll(`${target} .msl-news .msl-item`))
  const mslnewsContentPage = Array.from(document.querySelectorAll(`${target} .news_item`))
  const mslnews = mslnewsHomePage.concat(mslnewsContentPage)
  const ournews = mslnews.map(article => {
    // Gotta get creative here, as the MSL News Template tag outputs
    // different code to the Page Widget - We need to handle either
    let title = "Error"
    if (article.querySelector(".msl-title")) {
      title = article.querySelector(".msl-title").innerText
    } else {
      title = article.querySelector("h5").innerText
    }
    let link = "Error"
    if (article.querySelector("h3 a")) {
      link = article.querySelector("h3 a").href
    } else {
      link = article.querySelector("h5 a").href
    }
    let org = "Error"
    if (article.querySelector(".msl-organisation")) {
      org = [article.dataset.mslOrganisationId, article.querySelector(".msl-organisation").innerText]
    } else {
      org = [article.classList.toString().match(/mslorg(\d*)/)[1], article.querySelector(".msl_news_org").innerText]
    }

    let image = defaultimage
    if (article.querySelector(".msl-image img")) { image = article.querySelector(".msl-image img").src.split("?") }
    if (article.querySelector(".news_image img")) { image = article.querySelector(".news_image img").src.split("?") }

    const lead = article.querySelector('.msl-leader') ? article.querySelector('.msl-leader').innerText : article.querySelector('.leader').innerText

    let date = "Error"
    if (article.querySelector('.msl-pubdate')) {
      date = Date.parse(article.querySelector('.msl-pubdate').innerText)
    } else {
      date = Date.parse(article.querySelector('.msl_pubdate').innerText)
    }

    const mslimageoptions = "?thumbnail_width=460&amp;thumbnail_height=0&amp;resize_type=ResizeWidth"

    return {
      title,
      link,
      org,
      image: image[0] + mslimageoptions,
      lead,
      date
    }
  })

  function timeAgo(time) {
    const daysago = Math.round(moment.duration(moment().startOf('day') - time).asDays())
    switch (daysago) {
      case 0:
        return "today"
      case 1:
        return "yesterday"
      default:
        return `${daysago} days ago`
    }
  }

  function showOrg(orgname) {
    return true
    /*
    const noshow = [
      'Welcome',
      'Union of Students'
    ]
    */
  }

  function formatNews(newslist) {
    let html = ''
    newslist.forEach(article => {
      html += `

      <div class="newslist-item  swiper-slide  news-card ${Math.round(moment.duration(moment().startOf('day') - article.date).asDays()) > 60 ? ' news-old' : ''}" data-orgid="${article.org[0]}">
        <a class="news-card__link" href="${article.link}">
          <div class="news-card__image" style="background-image: url('${article.image}')">
            <div></div>
          </div>
          ${showOrg(article.org) ? `<div class="news-card__org">${article.org[1]}</div>` : ''}
          <div class="news-card__content">
            <div class="news-card__title">${article.title}</div>
            <div class="news-card__lead">${article.lead}</div>
            <div class="news-card__meta">
              <p>posted <time datetime="${article.date}" title="${article.date}">${timeAgo(article.date)}</time></p>
            </div>
          </div>
        </a>
      </div>

      `
    })
    return html
  }
  document.querySelector(target).innerHTML = formatNews(ournews)
}
